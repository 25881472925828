import axios from "axios";
import {
    BASE_URL_API,
    LS_TOKEN_KEY,
    URL_API_USER_ME,
    URL_API_USER_LOGIN,
    URL_RESERVATION,
    URL_CASH_REPORT_DAY,
    URL_TRANSACTION,
    URL_FEE_LOGS,
    URL_ANALYTICS,
    URL_ESTIMATE_RESERVATION,
    URL_BOOKING_RESERVATION,
    URL_SITES,
    URL_API_RELATIVE_OPENING_HOUR, URL_ANALYTICS_EXPORT, URL_STATEMENT, URL_INVOICE_NUMBER

} from "../common/constants";
import {Utils} from "../common/utils"
import {RESERVATION_CHANNEL, RESERVATION_STATES, PAYMENT_MODES} from "../common/keys";

export const client = axios.create({
  baseURL: BASE_URL_API,
  responseType: "json"
});

export function generateAuthorizationHeader() {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(LS_TOKEN_KEY)
    }
  };
}

export const doLoginUser = (username, password) => {
  const params = {
    username,
    password
  };
  return client
    .post(URL_API_USER_LOGIN, params)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getUserInfo = () => {
  return client
    .get(URL_API_USER_ME, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getReservations = (page, filter=null) => {
  let urlReservation = Utils.composeUrlWithLimitAndOffset(URL_RESERVATION, page);
  if (filter != null) {
    for (const [key, value] of Object.entries(filter)) {
      urlReservation += `&${key}=${value}`
    }
  }

  return client
    .get(urlReservation, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getReservationToConvalidate = (page, filter=null) => {
    let urlReservation = Utils.composeUrlWithLimitAndOffset(URL_RESERVATION+"?payment_mode=3&", page);
    if (filter != null) {
        for (const [key, value] of Object.entries(filter)) {
            urlReservation += `&${key}=${value}`
        }
    }

    return client
        .get(urlReservation, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getSites = () => {
    let urlSites = URL_SITES;

    return client
        .get(urlSites, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getCashReportDay = (payment_mode=null) => {
    let urlReservation = URL_CASH_REPORT_DAY + "?payment_mode=" + payment_mode;

    if (payment_mode == -1) {
        urlReservation = URL_CASH_REPORT_DAY + "?exclude_payment_mode=" + PAYMENT_MODES.TRANSFER;
    }

  return client
    .get(urlReservation, generateAuthorizationHeader())
    .then(res => {
      console.log(res.data);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getReservationDetail = (id) => {
    let urlReservation = URL_RESERVATION + id + "/";

    return client
        .get(urlReservation, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export const getSiteDetail = (id) => {
    let urlReservation = URL_SITES + id;
    console.log(urlReservation)
    return client
        .get(urlReservation, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export const getTransactions = (page, filter=null) => {
    let urlReservation = Utils.composeUrlWithLimitAndOffset(URL_TRANSACTION, page);
    if (filter != null) {
    for (const [key, value] of Object.entries(filter)) {
      if(value){
        urlReservation += `&${key}=${value}`
          console.log(urlReservation)
      }
    }
    
  }

  return client
    .get(urlReservation, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getFeeLogs = (page, filter=null) => {
  let urlReservation = Utils.composeUrlWithInfiniteLimit(URL_FEE_LOGS);
  if (filter != null) {
    for (const [key, value] of Object.entries(filter)) {
      if(value){
        urlReservation += `&${key}=${value}`
      }
    }
  }
  return client
    .get(urlReservation, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getStatement = (month=null, year=null) => {
    let urlStatement = URL_STATEMENT
    if (month != null && year != null) {
        urlStatement += `?month=${month+1}&year=${year}`
    }

    return client
        .get(urlStatement, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getAnalytics = (year=null, creator=null) => {
    let urlAnalytics = URL_ANALYTICS;

    if (year) {
        urlAnalytics += "?year=" + year
    }

    if (creator) {
        urlAnalytics += (year ? '&' : '?') + "creator=" +creator;
    }

    return client
        .get(urlAnalytics, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getInvoiceNumber = () => {
    let url = URL_INVOICE_NUMBER;

    return client
        .get(url, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const exportAnalytics = (year=null, creator=null) => {
    let urlAnalytics =  URL_ANALYTICS_EXPORT;

    if (year) {
        urlAnalytics += "?year=" + year
    }

    if (creator) {
        urlAnalytics += (year ? '&' : '?') + "creator=" +creator;
    }

    let header = generateAuthorizationHeader();
    header.responseType = 'blob';

    return client
        .get(urlAnalytics, header)
        .then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute(
                'download',

                "riviera_nuragica_statistiche_" + year + "_al_" + new Date().toLocaleDateString() + '.xlsx'
            );
            document.body.appendChild(link);
        link.click()
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const estimateReservation = (reservation_items,additional_discount_perc = 0) => {
    const params = {
        reservation_items,
        additional_discount_perc
    };
    return client
        .post(URL_ESTIMATE_RESERVATION, params)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const createReservation = (customer_first_name, customer_last_name, customer_email, customer_phone,
    customer_nationality, payment_mode, reservation_items, document_type, invoice_number,customer_vat_number,customer_pec,customer_address,customer_recipient_code,date, additional_discount_perc) => {
    const params = {
        customer_first_name,
        customer_last_name,
        customer_email,
        customer_phone,
        customer_nationality,
        payment_mode,
        reservation_items,
        document_type,
        invoice_number,
        customer_vat_number,
        customer_pec,
        customer_address,
        customer_recipient_code,
        date,
        additional_discount_perc
    };
    return client
        .post(URL_RESERVATION, params, generateAuthorizationHeader())
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


export const updateReservation = (reservation_id, customer_first_name, customer_last_name, customer_email, customer_phone,
                                  customer_nationality, payment_mode, date, document_type, invoice_number, customer_vat_number,
                                  customer_pec,customer_address,customer_recipient_code,cancel_reason) => {
    const params = {
        customer_first_name,
        customer_last_name,
        customer_email,
        customer_phone,
        customer_nationality,
        payment_mode,
        date,
        document_type,
        invoice_number,
        customer_vat_number,
        customer_pec,
        customer_address,
        customer_recipient_code,
        cancel_reason
    };
    return client
        .patch(URL_RESERVATION + reservation_id + "/", params, generateAuthorizationHeader())
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const cancelReservation = (reservation_id, cancel_reason) => {
    const params = {
        state: RESERVATION_STATES.CANCELED,
        cancel_reason
    };
    return client
        .patch(URL_RESERVATION + reservation_id + "/", params, generateAuthorizationHeader())
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


export const bookingReservation = (customer_first_name, customer_last_name, customer_email, customer_phone,
                                  customer_nationality, reservation_items, date, channel, language) => {

    if (!channel){
        channel = RESERVATION_CHANNEL.ONLINE_RN
    }

    const params = {
        customer_first_name,
        customer_last_name,
        customer_email,
        customer_phone,
        customer_nationality,
        reservation_items,
        date,
        channel,
        language
    };
    return client
        .post(URL_BOOKING_RESERVATION, params)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


export const checkInReservation = (reservation_id) => {

    return client
        .put(URL_RESERVATION + reservation_id + "/check-in", null, generateAuthorizationHeader())
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const updateSiteHours = (site,id,data) => {

    return client
        .put(URL_SITES + site + "/" + URL_API_RELATIVE_OPENING_HOUR + "/" + id, data, generateAuthorizationHeader())
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};
